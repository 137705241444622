import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	Typography,
} from "@material-ui/core";
import { Fragment, useState } from "react";
import { signIn, signOut } from "../redux/actions/coreActions";
import "./SignInDialog.css";

const SignInDialog = (props) => {
	const [values, setValues] = useState({ surname: "", pin: "" });
	const [error, setError] = useState("");

	const submitSignIn = () => {
		//Validate
		let valid = true;

		//Action
		if (valid) {
			signIn(values, (err) => {
				if (err) {
					setError(err);
				} else {
					props.close();
				}
			});
		}
	};

	const confirmSignOut = () => {
		signOut();
		props.close();
	};

	const handleEnter = (e) => {
		if (e.key === "Enter") {
			submitSignIn();
		}
	};

	return (
		<Dialog
			open
			onClose={props.close}
			className="signInDialog"
			PaperProps={{ square: true }}
		>
			{!props.token && <DialogTitle>Sign In</DialogTitle>}
			<DialogContent>
				{props.token ? (
					<Fragment>
						<Typography>Are you sure you want to sign out?</Typography>
					</Fragment>
				) : (
					<Fragment>
						<TextField
							autoComplete="true"
							id="surname"
							InputLabelProps={{ shrink: true }}
							// inputProps={{ type: "email" }}
							// color="#fff"
							label="Surname:"
							onChange={(e) => {
								setValues({ ...values, surname: e.target.value });
							}}
						/>
						<TextField
							InputLabelProps={{ shrink: true }}
							label="PIN:"
							onChange={(e) => {
								setValues({ ...values, pin: e.target.value });
							}}
							onKeyPress={handleEnter}
							helperText={error}
						/>
					</Fragment>
				)}
			</DialogContent>

			<DialogActions>
				{props.token ? (
					<Fragment>
						<Button onClick={confirmSignOut}>Yes</Button>
						<Button onClick={props.close}>No</Button>
					</Fragment>
				) : (
					<Fragment>
						<Button onClick={submitSignIn}>Sign In</Button>
						<Button onClick={props.close}>Cancel</Button>
					</Fragment>
				)}
			</DialogActions>
		</Dialog>
	);
};

export default SignInDialog;
