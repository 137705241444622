import { DateTime } from "luxon";
import {
	ADMIN_SETDELEGATELIST,
	ADMIN_SETROTATIONS,
	ADMIN_SETTEMPLATES,
	ADMIN_SET_TOKEN,
	ADMIN_UNSET_TOKEN,
	ADMIN_UPDATEDELEGATELIST,
} from "../types";

const initialState = {
	token: null,
	delegateList: [],
	listLastUpdated: null,
	rotations: [],
	templates: [],
	groups: ["1C", "2C", "3D", "4D", "A5", "A6", "B7", "B8"],
};

export default (adminState = initialState, action) => {
	switch (action.type) {
		case ADMIN_SET_TOKEN:
			localStorage.setItem(
				`token_admin_${process.env.REACT_APP_EVENTID}`,
				action.payload
			);
			return { ...adminState, token: action.payload };
		case ADMIN_UNSET_TOKEN:
			localStorage.removeItem(`token_admin_${process.env.REACT_APP_EVENTID}`);
			return { ...adminState, token: null };
		case ADMIN_SETDELEGATELIST:
			return {
				...adminState,
				delegateList: action.payload,
				listLastUpdated: DateTime.fromMillis(
					Math.max(...action.payload.map((i) => new Date(i.lastUpdated)))
				).toSQL(),
			};
		case ADMIN_UPDATEDELEGATELIST:
			let newList = [...adminState.delegateList];
			action.payload.forEach((i) => {
				let index = newList.findIndex((f) => f.id === i.id);
				if (index > -1) {
					newList[index] = { ...newList[index], ...i };
				} else {
					newList.push(i);
				}
			});

			return {
				...adminState,
				delegateList: newList,
				listLastUpdated: DateTime.fromMillis(
					Math.max(...action.payload.map((i) => new Date(i.lastUpdated)))
				).toSQL(),
			};
		case ADMIN_SETROTATIONS:
			return {
				...adminState,
				rotations: action.payload,
			};
		case ADMIN_SETTEMPLATES:
			return {
				...adminState,
				templates: action.payload,
			};
		default:
			return adminState;
	}
};
