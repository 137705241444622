import { createStore, applyMiddleware, compose } from "redux";
import rootReducer from "./redux/reducers";

const initialState = {};

const middleware = [];

const store = createStore(
	rootReducer,
	initialState,
	compose(
		applyMiddleware(...middleware),
		//Remove in production
		typeof window.__REDUX_DEVTOOLS_EXTENSION__ === "undefined"
			? (a) => a
			: window.__REDUX_DEVTOOLS_EXTENSION__ &&
					window.__REDUX_DEVTOOLS_EXTENSION__()
	)
);

export default store;