import axios from "axios";
import store from "../../store";
import {
	CORE_SET_ENABLEDVLA,
	CORE_SET_ENABLEEVAL,
	CORE_SET_PAGE,
	CORE_SET_TOKEN,
	CORE_UNSET_TOKEN,
	QUIZ_SETCURRENT,
	QUIZ_SETLIST,
	USER_SET,
	USER_UNSET,
	USER_UPDATE,
	VIDEO_SETLIST,
} from "../types";
import { handleReturnedError } from "./errorActions";

export const setPage = (page, isBack = false) => {
	store.dispatch({ type: CORE_SET_PAGE, payload: page });
	if (!isBack) {
		window.history.pushState({ page }, "BMW AUTUMN MODEL UPDATE EVENT 2023");
	}
};

export const init = () => {
	const token = localStorage.getItem(`token_${process.env.REACT_APP_EVENTID}`);
	if (token) {
		store.dispatch({ type: CORE_SET_TOKEN, payload: token });
		axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
	}
};

export const signIn = (user, cb) => {
	//Validate user
	axios
		.post(`/data/signIn.php`, { ...user, method: "microsite" })
		.then((response) => {
			//Set token
			store.dispatch({ type: CORE_SET_TOKEN, payload: response.data });
			axios.defaults.headers.common[
				"Authorization"
			] = `Bearer ${response.data}`;
			cb();
		})
		.catch((error) => {
			console.log(error.response.data);
			cb(error.response.data);
		});

	//Error
};

export const signOut = () => {
	//Unset token
	store.dispatch({ type: CORE_SET_PAGE, payload: "home" });
	store.dispatch({ type: CORE_UNSET_TOKEN, payload: null });
	store.dispatch({ type: USER_UNSET, payload: null });
};

export const setCurrentQuiz = (quizID) => {
	//Fetch quiz data
	//Dispatch quiz
	store.dispatch({ type: QUIZ_SETCURRENT, payload: quizID });
	//Set page
	setPage("quiz");
};
