import { QUIZ_SETCURRENT, QUIZ_SETLIST, QUIZ_UPDATE } from "../types";

const initialState = { quizList: [], currentQuiz: null };

// quizList: [
// 	{ quizId: 1, name: "My First Quiz", status: "inProgress" },
// 	{ quizId: 2, name: "My Completed Quiz", status: "complete" },
// 	{ quizId: 3, name: "My Unstarted Quiz", status: "incomplete" },
// 	{ quizId: 4, name: "My New Quiz", status: "new" },
// ],
// currentQuiz: {
// 	quizId: 1,
// 	name: "My First Quiz",
// 	description: "This quiz is about engines",
// 	qMax: 3,
// 	score: 2,
// 	questions: [
// 		{
// 			qNum: 1,
// 			question: "Question 1 is...",
// 			options: [
// 				"Parking Sensors",
// 				"Adaptive Braking",
// 				"Integrated Infotainment Applications",
// 				"Rear Wiper Blades",
// 			],
// 			correct: 1,
// 		},
// 		{
// 			qNum: 2,
// 			question: "Question 2 is...",
// 			options: ["A2", "B2", "R2", "D2"],
// 			correct: 2,
// 		},
// 		{
// 			qNum: 3,
// 			question: "Question 3 is...",
// 			options: ["A3", "B3", "C3", "D3"],
// 			correct: 3,
// 		},
// 	],
// },

export default (quizState = initialState, action) => {
	switch (action.type) {
		case QUIZ_SETLIST:
			return { ...quizState, quizList: action.payload };
		case QUIZ_SETCURRENT:
			return { ...quizState, currentQuiz: action.payload };

		case QUIZ_UPDATE:
			let newQuizData = {
				...quizState.quizList[action.payload.quizID],
				score:
					quizState.quizList[action.payload.quizID].score +
					action.payload.score,
				maxQ: action.payload.questionNum,
				complete: Boolean(
					action.payload.questionNum >=
						quizState.quizList[action.payload.quizID].qMax
				),
				rank: action.payload.rank,
			};
			let newList = { ...quizState.quizList };
			delete newList[action.payload.quizID];
			newList[action.payload.quizID] = newQuizData;
			return {
				...quizState,
				quizList: newList,
			};
		default:
			return quizState;
	}
};
