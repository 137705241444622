import { CORE_SET_PAGE, CORE_SET_TOKEN, CORE_UNSET_TOKEN } from "../types";

const initialState = {
	page: "home",
	token: null,
};

export default (coreState = initialState, action) => {
	switch (action.type) {
		case CORE_SET_PAGE:
			return { ...coreState, page: action.payload };
		case CORE_SET_TOKEN:
			localStorage.setItem(
				`token_${process.env.REACT_APP_EVENTID}`,
				action.payload
			);
			return { ...coreState, token: action.payload };
		case CORE_UNSET_TOKEN:
			localStorage.removeItem(`token_${process.env.REACT_APP_EVENTID}`);
			return { ...coreState, token: null };
		default:
			return coreState;
	}
};
