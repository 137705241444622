import { combineReducers } from "redux";

import quizReducer from "./quizReducer";
import coreReducer from "./coreReducer";
//import videoReducer from "./videoReducer";
import userReducer from "./userReducer";
import adminReducer from "./adminReducer";

export default combineReducers({
	core: coreReducer,
	quiz: quizReducer,
	// video: videoReducer,
	user: userReducer,
	admin: adminReducer,
});
