export const CORE_SET_PAGE = "coreSetPage";
export const CORE_SET_TOKEN = "coreSetToken";
export const CORE_UNSET_TOKEN = "coreUnsetToken";
export const USER_SET = "userSet";
export const USER_UPDATE = "userUpdate";
export const USER_UNSET = "userUnset";
export const QUIZ_SETLIST = "quizSetList";
export const QUIZ_UPDATE = "quizUpdate";
export const QUIZ_SETCURRENT = "quizSetCurrent";
export const VIDEO_SETLIST = "videoSetList";
export const VIDEO_SETCURRENT = "videoSetCurrent";

export const ADMIN_SETDELEGATELIST = "adminDelegateListSet";
export const ADMIN_UPDATEDELEGATELIST = "adminDelegateListUpdate";
export const ADMIN_SETTEMPLATES = "adminTemplatesSet";
export const ADMIN_SETROTATIONS = "adminRotationsSet";
export const ADMIN_SET_TOKEN = "adminSetToken";
export const ADMIN_UNSET_TOKEN = "adminUnsetToken";
