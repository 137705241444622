import {
	Button,
	CircularProgress,
	StylesProvider,
	Typography,
} from "@material-ui/core";
import { Fragment, Suspense, lazy, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import "./App.css";

import { MiniPersonIcon } from "./components/ExtraIcons";
import { init, setCurrentQuiz, setPage } from "./redux/actions/coreActions";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import SignInDialog from "./components/SignInDialog";
const AppContent = lazy(() => import("./AppContent"));

// const DVLAPage = lazy(() => import("./components/pages/DVLAPage"));
// const DisclaimerPage = lazy(() => import("./components/pages/DisclaimerPage"));
// const EventInfoPage = lazy(() => import("./components/pages/EventInfoPage"));
// const MyDetailsPage = lazy(() => import("./components/pages/MyDetailsPage"));
// const QuizPage = lazy(() => import("./components/pages/QuizPage"));
// const QuizzesPage = lazy(() => import("./components/pages/QuizzesPage"));
// const ContainerPage = lazy(() => import("./components/pages/ContainerPage"));
// const EvaluationPage = lazy(() => import("./components/pages/EvaluationPage"));
// const GeniusBarPage = lazy(() => import("./components/pages/GeniusBarPage"));

function App(props) {
	const page = useSelector((state) => state.core.page);
	const token = useSelector((state) => state.core.token);
	const videoPlayed = useSelector((state) => state.user.videoPlayed);

	const [showSignIn, setShowSignIn] = useState(false);
	//const isDesktop = useMediaQuery("(min-width: 1024px)");
	const [loaded, setLoaded] = useState(false);
	const params = useParams();

	const [pp, setPP] = useState(false);
	const ref = useRef();

	// const historyChange = (e) => {
	// 	if (e.state) {
	// 		setPage(e.state.page, true);
	// 	}
	// };

	useEffect(() => {
		// if (params.quizid) {
		// 	setCurrentQuiz(params.quizid);
		// }
		// if (props.page) {
		// 	setPage(props.page);
		// }
		init();

		// window.addEventListener("popstate", historyChange);

		// return () => {
		// 	window.removeEventListener("popstate", historyChange);
		// };
	}, []);

	useEffect(() => {
		setLoaded(true);
	}, [token]);

	// useEffect(() => {
	// 	if (ref.current) {
	// 		ref.current.scrollTop = 0;
	// 	}
	// }, [page]);

	const handleShowSignIn = () => {
		setShowSignIn(true);
	};

	const handleHideSignIn = () => {
		setShowSignIn(false);
	};

	// const pageContent = () => {
	// 	switch (page) {
	// 		case "disclaimer":
	// 			return <DisclaimerPage />;
	// 		case "eventInfo":
	// 			return <EventInfoPage />;
	// 		case "dvla":
	// 			return <DVLAPage />;
	// 		case "quizzes":
	// 			return <QuizzesPage />;
	// 		case "quiz":
	// 			return <QuizPage />;
	// 		case "mydetails":
	// 			return <MyDetailsPage />;
	// 		case "container-623":
	// 			return (
	// 				<ContainerPage
	// 					title="#DRIVE."
	// 					sectionID={623}
	// 					question="What were your key learnings and observations from the Ionity Charging activity?"
	// 				/>
	// 			);
	// 		case "container-624":
	// 			return (
	// 				<ContainerPage
	// 					title="#FUTURE."
	// 					sectionID={624}
	// 					question="What were you first impressions of the new BMW X2?"
	// 				/>
	// 			);
	// 		case "evaluation":
	// 			return <EvaluationPage />;
	// 		case "geniusbar":
	// 			return <GeniusBarPage />;
	// 		case "home":
	// 		default:
	// 			return <HomePage />;
	// 	}
	// };

	return (
		<div className={`App ${!token && loaded ? "appHolding " : ""}`} ref={ref}>
			<StylesProvider injectFirst>
				<header
					id="appHeader"
					className={`${!token ? "appHeaderHolding" : ""}`}
				>
					<div id="appHeaderLogos">
						{token && (
							<div id="logoBMWGroup">
								<img src="/images/header5.png" />
							</div>
						)}

						<div id="logoSpacer"></div>
						<div id="logobmwamu23">
							<img src="/images/BMWLogos.svg" />
						</div>
					</div>
					{/* {loaded && !token ? (
						<div id="holdingTitle">
							<Typography variant="h2">
								WELCOME TO BMW AUTUMN MODEL UPDATE 2023.
							</Typography>
						</div>
					) : null} */}

					{/* {token && videoPlayed && !params.quizid && !props.page ? (
						<div className={`navbarLines`}>
							<HomeNavbar onHomeSignOut={homeOrSignOut} page={page} />
						</div>
					) : null} */}
					{loaded && !token ? (
						<div className={`holdingSignIn`}>
							<Button
								id="btnHeader"
								onClick={handleShowSignIn}
								variant="outlined"
								startIcon={<MiniPersonIcon />}
							>
								<Typography>SIGN IN</Typography>
							</Button>
						</div>
					) : null}
				</header>

				{token ? (
					<Suspense
						fallback={
							<div className="loadingSpinner">
								<CircularProgress />
							</div>
						}
					>
						<AppContent page={props.page} showSignIn={handleShowSignIn} />
					</Suspense>
				) : null}
				{showSignIn && <SignInDialog close={handleHideSignIn} token={token} />}

				{page === "home" && token ? (
					<div className="contactLink">
						<a href="mailto:eventsteam@bmwtraining.co.uk">
							eventsteam@bmwtraining.co.uk
						</a>
						<br />
						07385 788 181
					</div>
				) : null}

				{page === "home" || !token ? (
					<div className="privacyPolicyLink" onClick={() => setPP(true)}>
						Privacy Policy
					</div>
				) : null}
				{pp ? <PrivacyPolicy close={() => setPP(false)} /> : null}
			</StylesProvider>
		</div>
	);
}

export default App;
