import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";

import App from "./App";
import "./index.css";
import store from "./store";
const Admin = lazy(() => import("./Admin"));

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<Suspense fallback={<div></div>}>
				<Router>
					<Routes>
						<Route path="/" exact element={<App />} />
						<Route path="/quiz/623" element={<App page="container-623" />} />
						<Route path="/quiz/624" element={<App page="container-624" />} />
						<Route path="/quiz/:quizid" element={<App />} />
						<Route path="/evaluation" element={<App page="evaluation" />} />
						<Route
							path="/admin/geniusbar"
							element={<Admin geniusBar={true} />}
						/>
						<Route path="/admin/group/:group" element={<Admin />} />
						<Route path="/admin/setgroup" element={<Admin setGroup={true} />} />
						<Route path="/admin" exact element={<Admin />} />
						<Route path="*" element={<App />} />
					</Routes>
				</Router>
			</Suspense>
		</Provider>
	</React.StrictMode>,
	document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
//reportWebVitals(console.log);
