import {
	Dialog,
	DialogTitle,
	DialogContent,
	DialogActions,
	Button,
	Typography,
} from "@material-ui/core";

const PrivacyPolicy = (props) => {
	return (
		<Dialog
			open
			onClose={props.close}
			className="privacyPolicy"
			fullWidth
			maxWidth="lg"
		>
			<DialogTitle>PRIVACY POLICY</DialogTitle>
			<DialogContent>
				<Typography variant="h4">PRIVACY STATEMENT</Typography>
				<Typography paragraph>
					This site, www.bmwtraining.co.uk (the “Site”), is operated by Event
					Technologies Ltd (“We”, “Our” or ”Us”) on behalf of AMCI Europe Ltd
					&amp; BMW (UK) Ltd (jointly “the Event Organisers”). To this extent we
					act as both data processors of Personally Identifiable Information
					(“PII”) provided by the Event Organisers and data controllers for
					information collected though the Site.
				</Typography>
				<Typography paragraph>
					We and the Event Organisers will take appropriate measures to protect
					the PII provided by and collected from you on the Site. This PII is
					collected for the following purposes:
				</Typography>
				<ul>
					<li>
						Sending of email communications related to the Site and associated
						training event(s)
					</li>
					<li>
						Performing of DLVA driver licence checks to confirm driver
						eligibility
					</li>
					<li>Organisation and delivery of the associated training event(s)</li>
				</ul>
				<Typography paragraph>
					We will not disclose, sell, trade, or rent PII, except for disclosure
					to the Event Organisers and third parties (listed below) to the extent
					necessary to provide the functions of the Site. In certain rare
					circumstances, we may be compelled by law to disclose your PII, such
					as in connection with a lawsuit or other legal proceeding. In the
					event disclosure is required, we will use reasonable commercial
					efforts to try to secure confidential treatment of any disclosed PII,
					including prohibiting use of the information for any commercial
					purpose.
				</Typography>
				<Typography paragraph>
					In addition, we reserve the right to disclose certain information
					when, in its sole discretion, it determines that such disclosure is
					necessary to protect us or our employees, their rights or property, or
					to protect the physical safety or health of our employees or a member
					of the general public.
				</Typography>

				<Typography variant="h4">DATA PROTECTION</Typography>
				<Typography paragraph>Controller/Processor Details:</Typography>
				<Typography paragraph>
					Event Technologies Ltd, Office 9, Antrobus House Business Centre, 18
					College Street, Petersfield, GU31 4AD - 01730 711300 -
					enquiries@event-technologies.co.uk
				</Typography>
				<Typography paragraph>
					AMCI Europe Ltd, Unit D Cherwell Business Village, Southam Road,
					Banbury, Oxfordshire, England, OX16 2SP
				</Typography>
				<Typography paragraph>
					BMW (UK) Ltd, Company Secretary, Summit One, Summit Avenue,
					Farnborough, Hampshire, GU14 0FB
				</Typography>
				<Typography paragraph>
					Data collected though the Site is handled by the following third
					parties under contract with us for the stated purposes:
				</Typography>

				<ul>
					<li>UKFast.Net Limited – Secure server hosting</li>
					<li>MailGun.com – Email automation</li>
				</ul>
				<Typography paragraph>
					EU Data subjects have rights to request the erasure, rectification,
					restriction of processing as well as the right to receiving a copy of
					all data held by us on the requesting data subject. If you request
					erasure of PII, you acknowledge that residual PII may continue to
					reside in our records and archives, where it is not technically
					feasible for us to remove this information, but we warrant that will
					not be used for commercial purposes.
				</Typography>
				<Typography paragraph>
					Data subjects have the right to withdraw consent for information
					collection at any time.
				</Typography>
				<Typography paragraph>
					Data subjects have the right to lodge a complaint with a supervisory
					authority in their member state.
				</Typography>
				<Typography paragraph>
					Data subjects may access their rights by contacting the data
					controller specified in this agreement using the contact details
					provided.
				</Typography>

				<Typography variant="h4">PERSONAL INFORMATION COLLECTION</Typography>
				<Typography paragraph>
					The following areas of PII may be collected as part of the use of the
					Site:
				</Typography>
				<ul>
					<li>Name</li>
					<li>E-mail address</li>
					<li>Phone number</li>
					<li>Driving Licence Number</li>
					<li>Date of Birth</li>
					<li>Dietary Requirements</li>
					<li>Emergency Contact Name</li>
					<li>Emergency Contact Relationship</li>
					<li>Emergency Contact Number</li>
					<li>Emergency Contact Address</li>
				</ul>
				<Typography variant="h4">
					PURPOSES OF COLLECTION AND RETENTION PERIOD
				</Typography>
				<Typography paragraph>
					PII is collected for the sole purpose of the functions of the Site and
					the organisation and delivery of the associated training event(s).
					Collected PII will be passed to the Event Organisers &amp; this will
					be managed in accordance to their own Data Protection &amp; Privacy
					Policies. We are not responsible for any use or right to be forgotten
					by these parties.
				</Typography>
				<Typography paragraph>
					PII will be removed from the Site within 3 months of the end of the
					associated training event(s) in which the user has participated,
					unless otherwise required by the Event Organisers.
				</Typography>
				<Typography variant="h4">COOKIES</Typography>
				<Typography paragraph>
					In order to facilitate some interactions on the Site we store some
					minimal data in your browser, commonly referred to as “cookies”. These
					are set by us and our third-party providers for necessary functional
					and analytical purposes only. You can adjust your browser settings to
					refuse or inform of cookies, however, your election not to accept may
					diminish your experience with the Site.
				</Typography>
			</DialogContent>
			<DialogActions>
				<Button onClick={props.close}>Close</Button>
			</DialogActions>
		</Dialog>
	);
};

export default PrivacyPolicy;
