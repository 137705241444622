import { USER_SET, USER_UNSET, USER_UPDATE } from "../types";

const initialState = {};

export default (userState = initialState, action) => {
	switch (action.type) {
		case USER_SET:
			return action.payload;
		case USER_UPDATE:
			return { ...userState, ...action.payload };
		case USER_UNSET:
			return initialState;

		default:
			return userState;
	}
};
